import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { SectionWrapper } from "../components/sections";
import { ArrowRight } from "../components/icon/arrow-left";
import { pageDocPath } from "../../helpers";

const REDIRECT_DELAY = 1000;

export const query = graphql`
  query IntegrationTemplateQuery($id: String!) {
    doc: sanityIntegration(id: { eq: $id }) {
      _type
      id
      _id
      company
      docRef {
        _id
        _type
        title
        slug {
          current
        }
        menu
        menuParent
      }
    }
  }
`;

const IntegrationTemplate = ({ data }) => {
  const isBrowser = typeof window !== "undefined";
  const path = pageDocPath(data?.doc.docRef);

  useEffect(() => {
    if (isBrowser && path) {
      setTimeout(() => {
        window.location.replace(path);
      }, REDIRECT_DELAY);
    }
  }, []);

  return (
    <LayoutContainer doc={{ ...data?.doc }}>
      <PageHeadMeta title="Redirect" doc={data?.doc} />
      <SectionWrapper layout="centered" container="large">
        <div className="text-center my-16">
          <a
            href={path}
            className="group hover:bg-dark-2 p-16 rounded-2xl block"
          >
            <p className="text-lg">
              <span className="mr-1">Redirecting to</span>
              <span className="font-semibold text-link group-hover:text-black">
                {data?.doc.docRef.title} <ArrowRight />
              </span>
            </p>
            <p className="mt-2 text-secondary font-mono">{path}</p>
          </a>
        </div>
      </SectionWrapper>
    </LayoutContainer>
  );
};

export default IntegrationTemplate;
